<template>
  <svg
    :width="props.width"
    viewBox="0 0 194 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    :class="classColorLogo"
  >
    <path
      d="M60.62.23h-5.85l-8.39 16.85-3.61-5.62.38-.08a4.47 4.47 0 0 0 1.93-.92C46.46 9.38 47 7.7 47 6 47 2.62 44.46.23 41.08.23H27.54v19.7h6.15v-7.24h3.93l4.46 7.23h9.23l1.77-3.3h9.38l1.77 3.3h6.3L60.63.23ZM38.69 8.7h-5V4.4h4.93c1.46 0 2.23.61 2.23 2.15 0 1.46-.7 2.15-2.16 2.15ZM54.46 13l3.23-6.7 3.16 6.7h-6.39ZM0 .3h13.15c-2.38 1.4-4.38 3.62-4.38 7.16v12.39H0V.3Z"
    />
    <path
      d="M14.08 10.46a4.43 4.43 0 0 1 3.38-7.23c.85 0 1.62.23 2.3.7l-5.68 6.53Zm6.77-5.54a4.43 4.43 0 0 1-3.38 7.23c-.85 0-1.7-.23-2.4-.69l5.78-6.54ZM10.15 7.7c0 4 3.31 7.31 7.31 7.31 4 0 7.3-3.3 7.3-7.3S21.55.37 17.55.37a7.42 7.42 0 0 0-7.39 7.31ZM100.39.23H90.23l-4.61 9.23L80 .31l-5.62 9.15L69.77.23h-6.15L73.23 20h2.23l4.39-8.92L84.54 20h2.23l8.15-16.92v16.84h10.16l2.46-4.84h-7.16V.23ZM134.23.08h-5.46V20h10.15l2.47-4.92h-7.16v-15ZM160.54.08h-5.62v10.46c0 2.61-.53 4.46-3.38 4.46-.54 0-1.16 0-1.7-.23-1.53-.62-1.76-2.62-1.76-4.08V.08h-5.77v10.46c0 1.54 0 3.54.46 5 .23.84.61 1.54 1.23 2.15.7.77 1.7 1.31 2.7 1.62 1.45.54 3.53.61 5 .61 1.45 0 3.07-.07 4.45-.61.93-.31 2-.77 2.7-1.46.69-.7 1.07-1.62 1.3-2.54.39-1.46.39-3.54.39-5.08V.08ZM180.46 6.77V1.85c-2.46-1.16-5-1.77-7.7-1.77-5.68 0-11.07 4.07-11.07 10.07.16 6.85 4.39 9.54 10.93 9.85 2.92 0 5.92-.7 7.84-3.08v-6.15H175v3.54c-.23.54-.77.77-1.3.84-3.93.31-6.31-1.23-6.31-5.3 0-1 .23-1.93.76-2.7 1.08-1.61 3.08-2.15 5-2.23 2.47-.15 5.08.7 7.31 1.85ZM120.92.23l-12.3.08V20h5.84v-6.7c5.16 0 12.7 1.32 12.77-7.07.08-3.38-2.84-6-6.3-6Zm-1.53 8.62h-4.78V4.23h4.78c1.22 0 2.46.39 2.46 2.3-.08 1.7-.93 2.32-2.46 2.32ZM189.08 8.62a4.3 4.3 0 1 1 4.3-4.31 4.4 4.4 0 0 1-4.3 4.3Zm0-7.7a3.45 3.45 0 0 0-3.47 3.46 3.45 3.45 0 0 0 3.47 3.47 3.45 3.45 0 0 0 3.46-3.47 3.5 3.5 0 0 0-3.46-3.46Z"
    />
    <path
      d="M188.62 1.92c.69 0 1.15.16 1.53.39.39.23.54.61.54 1.15 0 .7-.3 1.08-.92 1.3.08.09.15.24.3.4.08.15.24.3.31.53.08.16.24.39.31.54l.31.54h-1c-.08-.15-.15-.3-.3-.54-.08-.15-.24-.3-.31-.46-.08-.15-.24-.3-.31-.46-.08-.16-.16-.31-.31-.39h-.7v1.85h-.84V2c.23-.08.46-.08.7-.08h.69Zm.07.77h-.46v1.54h.92c.16 0 .31-.08.39-.15l.23-.23c.08-.08.08-.23.08-.39 0-.15 0-.3-.08-.38l-.23-.23c-.08-.08-.23-.08-.39-.16h-.46Z"
    />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { Variant } from './UiLogo.types'

interface UiLogoProps {
  width?: number
  variant?: Variant
}

const props = withDefaults(defineProps<UiLogoProps>(), {
  width: 194,
  variant: 'brand',
})

const classColorLogo = computed<string>(() => {
  return props.variant === 'white' ? 'fill-white' : 'fill-primary'
})
</script>
